<template>
    <modal ref="modalHistorialRecompensa" titulo="Historial de recompensas" tamano="modal-xl" no-aceptar no-cancelar>
        <!-- <div class="row mx-0 py-4" /> -->
        <div class="row mx-0">
            <div class="col">
                <div class="row mx-0 mb-2 align-items-center">
                    <img :src="datos.imagen" :class="` ${origin == 1 ? 'rounded-circle' : 'br-2' }  obj-cover border `" width="42" height="42" />
                    <div class="col px-2 text-general f-18 f-500">
                        {{ datos.nombre }}
                    </div>
                </div>
                <div v-if="origin==1" class="row mx-0 align-items-center">
                    <div style="width:36px;" />
                    <i class="icon-leechero-cap f-20 text-general" />
                    <div class="col px-2 text-general f-16">
                        {{ datos.leechero_nombre }}
                    </div>
                </div>
                <div v-if="origin==2" class="row mx-0 align-items-center">
                    <div style="width:36px;" />
                    <i class="icon-cedis f-20 text-general" />
                    <div class="col px-2 text-general f-16">
                        {{ datos.cedis_nombre }}
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0 mb-3">
                    <div style="width:36px;" />
                    <div class="col px-0">
                        <p class="text-general f-15 f-500 mb-3">
                            Saldo
                        </p>
                        <div class="row mx-0">
                            <div class="saldo-box position-relative text-center d-middle-center">
                                <img src="/img/gaming/i_moneda.svg" class="position-absolute" height="46" style="left:-25px;" />
                                {{ datos.monedas_actual || 0 }}
                            </div>
                            <div class="col-1" />
                            <div class="saldo-box position-relative text-center d-middle-center">
                                <img src="/img/gaming/i_gema.svg" class="position-absolute" height="46" style="left:-25px;" />
                                {{ datos.gemas_actual || 0 }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0 mb-3">
                    <div style="width:36px;" />
                    <div class="col px-0">
                        <p class="text-general f-15 f-500 mb-3">
                            Por recoger
                        </p>
                        <div class="row mx-0">
                            <div class="saldo-box position-relative text-center d-middle-center">
                                <img src="/img/gaming/i_moneda.svg" class="position-absolute" height="46" style="left:-18px;" />
                                {{ datos.monedas_ganadas || 0 }}
                            </div>
                            <div class="col-1" />
                            <div class="saldo-box position-relative text-center d-middle-center">
                                <img src="/img/gaming/i_gema.svg" class="position-absolute" height="46" style="left:-18px;" />
                                {{ datos.gemas_ganadas || 0 }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0 mb-3">
                    <div style="width:36px;" />
                    <div class="col px-0">
                        <p class="text-general f-15 f-500 mb-3">
                            Redimidos
                        </p>
                        <div class="row mx-0">
                            <div class="saldo-box position-relative text-center d-middle-center">
                                <img src="/img/gaming/i_moneda.svg" class="position-absolute" height="46" style="left:-18px;" />
                                {{ datos.monedas_ajuste || 0 }}
                            </div>
                            <div class="col-1" />
                            <div class="saldo-box position-relative text-center d-middle-center">
                                <img src="/img/gaming/i_gema.svg" class="position-absolute" height="46" style="left:-18px;" />
                                {{ datos.gemas_ajuste || 0 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0 mb-3">
                    <div class="col-auto text-general f-18">
                        <span class="f-500">Tipo:</span>
                        <span class="f-400 ml-2">{{ datos.tipo }}</span>
                    </div>
                    <div class="col-auto text-general ml-4 f-18">
                        <span class="f-500">Usuario desde:</span>
                        <span class="f-400 ml-2">{{ formatearFecha(datos.created_at) }}</span>
                    </div>
                </div>
                <p v-if="recompensasHistorial.length" class="text-general f-15 f-500 pl-3">
                    Uso de recompensas y premios
                </p>
                <div v-for="(historial,index) in recompensasHistorial" :key="index" class="row mx-0 border align-items-center br-8 py-2 px-3 mb-3 " style="min-height:64px;">
                    <!-- col general -->
                    <div v-if="!historial.pedido" class="col-3 px-0">
                        <p class="text-general f-15 f-500">
                            Canje No. {{ historial.id }}
                        </p>
                        <p class="text-general f-15 mt-1">
                            {{ formatearFecha(historial.created_at) }}
                        </p>
                    </div>
                    <!-- col pedido -->
                    <div v-if="historial.pedido" class="col-3 px-0">
                        <p class="text-general f-15 f-500">
                            Pedido No. {{ historial.pedido.id }}
                        </p>
                        <p class="text-general f-15 mt-1">
                            {{ formatearFecha(historial.pedido.entrega_fecha) }}
                        </p>
                    </div>

                    <!-- col media -->
                    <div v-if="!historial.diario_dia" class="col-3 px-0 bg-fondo border px-3 ml-4 br-4">
                        <div class="row align-items-center mx-0 " style="height: 45px;">
                            <div class="col-auto px-0 d-middle">
                                <img src="/img/gaming/i_moneda.svg" height="15" />
                                <span class="f-500 text-general f-14">
                                    {{ historial.monedas_actual || 0 }}
                                </span>
                            </div>
                            <div class="col-auto px-0 d-middle ml-auto">
                                <img src="/img/gaming/i_gema.svg" height="15" />
                                <span class="f-500 text-general f-14 text-center">
                                    {{ historial.monedas_gemas || 0 }}
                                </span>
                            </div>
                            <p v-if="historial.pedido" class="text-general f-15 ml-3 justify-center">
                                {{ historial.pedido.descuento }}
                            </p>
                        </div>
                    </div>

                    <!-- caso recompensa producto -->
                    <div v-if="historial.pedido && !historial.diario_dia" class="col-auto px-0 ml-4 text-center ">
                        <p class="text-general f-15 f-500">
                            {{ convertMoney(historial.pedido.valor_final) }}
                        </p>
                        <p class="text-general f-15 mt-1">
                            {{ historial.pedido.propietario_nombre }}
                        </p>
                    </div>

                    <!-- caso recompensa producto ruleta -->
                    <div v-if="historial.diario_dia" class="col-auto text-center bg-fondo border px-3 ml-4 br-4" style="width: 121px;">
                        <div class="d-middle text-center f-15 f-500 ">
                            <img src="/img/gaming/i_ruleta.svg" height="15" class="ml-2" />
                            <p class="text-general">Ruleta</p>
                        </div>
                        <p class="text-general f-15 mt-1">
                            Día {{ historial.diario_dia }}
                        </p>
                    </div>

                    <img v-if="historial.producto" :src="historial.producto.imagen" class="col-auto obj-cover br-4 border ml-4 text-center" height="54px" width="54px" />
                </div>
                <div v-if="recompensasHistorial.length" class="row mx-0 align-items-center justify-center mt-3">
                    <div class="col" />
                    <div class="br-20 text-white d-middle-center f-14 cr-pointer bg-general3" :style="`height: 28px;width:72px; ${!len ? 'pointer-events: none; opacity: 0.5;' : ''} `" @click="listarHistorialRecompensas()">
                        Ver +10
                    </div>
                </div>
                <div v-else class="row align-center mt-5 justify-center py-0 px-0">
                    <img class="col-auto mb-3" src="/img/no-imagen/sin_pedidos.svg" height="200px" width="200px" />
                    <p class="col-auto text-general2"> El {{ origin == 1 ? 'cliente' : 'leechero' }} no ha redimido sus recompensas.  </p>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>

import UsuariosRecompensas from '~/services/gaming/gamingUsuariosRecompensas'

export default {
    data(){
        return {
            len: null,
            recompensasHistorial: [],
            datos:[],
            origin: null,
            idUser: null,
        }
    },
    computed: {
        nombreUsuario(){
            switch (this.origin){
            case 1:
                return this.datos.nombre
            case 2: 
                return this.datos.leechero_nombre
            default:
                return 'No registra'
            }
        }
    },
    mounted(){
        this.recompensasHistorial = []
    },
    methods: {
        toggle(idUser,origin){
            this.recompensasHistorial = []
            this.idUser = idUser
            this.origin = origin
            this.listarDetalleRecompensas()
            this.listarHistorialRecompensas()
            this.$refs.modalHistorialRecompensa.toggle();
        },
        async listarDetalleRecompensas(){
            try {
                if(this.origin==1){
                    
                    const { data } = await UsuariosRecompensas.getDetalleRecompensasCliente(this.idUser)
                    this.datos = data.data
                }
                if(this.origin==2){
                    
                    const { data } = await UsuariosRecompensas.getDetalleRecompensasLeechero(this.idUser)
                    this.datos = data.data
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarHistorialRecompensas(take=10){
            try {

                let params = {
                    take: take,
                    offset: this.recompensasHistorial.length ? this.recompensasHistorial.length : 0,
                    id_user: this.idUser
                }

                if(this.origin==1){
                    const { data } = await UsuariosRecompensas.getHistorialRecompensasCliente({params})
                    this.recompensasHistorial = [...this.recompensasHistorial,...data.data]
                    this.len = data.data.length
                }   
                if(this.origin==2){
                    const { data } = await UsuariosRecompensas.getHistorialRecompensasLeechero({params})
                    this.recompensasHistorial = [...this.recompensasHistorial,...data.data]
                    this.len = data.data.length
                }
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.saldo-box{
    border: 1px solid #DBDBDB;
    background: #F6F9FB;
    border-radius: 4px;
    width: 90px;
    height: 28px;
    color: #000000;
    font-weight: 500;
}
</style>